import { useEffect } from 'react';
import { api_url_base } from '../types/constants';import './ThingsToDoView.css';

export function ThingsToDoView() {

  useEffect(() => {
    fetch(`${api_url_base}v1/healthcheck`)
      .then(response => {
        if (response.ok) {
          console.log(`OK`)
        }
        else {
          console.log(`Unable to connect to API`)
        }
      })
  },[])

  return (
    <div className='center'>
      THINGS TO DO COMING SOON...
    </div>
  )
}