export enum items {
  // CLOTHES
  SOCKS = 'Socks',
  PANTS = 'Pants',
  SHORTS = 'Shorts',
  SWIMWEAR = 'Swimwear',
  DRESS_OR_SKIRTS = 'Dresses or Skirts',
  FORMAL_WEAR = 'Formal Wear',
  BELT = 'Belt',
  UNDERWEAR = 'Underwear',
  BRAS = 'Bras',
  SHORT_SLEEVE_SHIRTS = 'Short-Sleeve Shirts',
  LONG_SLEEVE_SHIRTS = 'Long-Sleeve Shirts',
  UNDER_SHIRTS = 'Under Shirts',
  DRESS_SHIRTS = 'Dress Shirts',
  RUNNING_SHOES = 'Runing Shoes',
  BOOTS = 'Boots',
  DRESS_SHOES = 'Dress Shoes',
  DRESS_SOCKS = 'Dress Socks',
  SUIT = 'Suits',
  HEELS = 'Heels',
  FLIP_FLOPS = 'Flip Flops',
  RAIN_JACKET = 'Rain Jacket',
  WINTER_COAT = 'Winter Coat',
  HIKING_CLOTHES = 'Hiking Clothes',
  HIKING_BOOTS = 'Hiking Boots',
  WORKOUT_CLOTHES = 'Workout Clothes',
  THERMAL_WEAR = 'Thermal Wear',
  BEANIES = 'Beanies',
  HATS = 'Hats',
  GLOVES = 'Gloves',
  TIES = 'Ties',
  WATCH = 'Watch',
  SUNGLASSES = 'Sunglasses',
  // TOILETRIES
  TOOTHBRUSH = 'Toothbrush',
  TOOTHPASTE = 'Toothpaste',
  MOUTHWASH = 'Mouthwash',
  FACE_WASH = 'Face Wash',
  SKIN_HAIR_CARE = 'Skin / Hair Care',
  LOTION = 'Lotion',
  SUNSCREEN = 'Sunscreen',
  HAND_SANITIZER = 'Hand Sanitizer',
  SHAVER = 'Shaver',
  GLASSES = 'Glasses',
  CONTACT_LENSES = 'Contact Lenses',
  // ELECTRONICS
  LAPTOP_AND_CHARGER = 'Laptop and Charger',
  PHONE_CHARGER = 'Phone Charger / Cables',
  PORTABLE_BATTERY = 'Portable Battery + Cables',
  CAMERA_AND_CHARGER = 'Camera and Charger',
  HEADLAMP = 'Headlamp',
  HEADPHONES = 'Headphones',
  // INTERNATIONAL
  INTERNATIONAL_POWER_CONVERTER = 'International Power Converter',
  PASSPORT = 'Passport',
  CURRENCY = 'Currency',
  // CAMPING
  CAMPING_LIGHT = 'Camping Light',
  TENTS = 'Tents',
  SLEEPING_BAGS = 'Sleeping Bags',
  CAMPING_PILLOWS = 'Camping Pillows',
  HIKING_BACKPACK = 'Hiking Backpack',
  INSECT_REPELLANT = 'Insect Repellant',
  // OTHER
  WATER_BOTTLE = 'Water Bottle',
  UMBRELLA = 'Umbrella'
}

export const clothesItems = new Set([
  items.SOCKS,
  items.PANTS,
  items.SHORTS,
  items.SWIMWEAR,
  items.DRESS_OR_SKIRTS,
  items.FORMAL_WEAR,
  items.BELT,
  items.UNDERWEAR,
  items.BRAS,
  items.SHORT_SLEEVE_SHIRTS,
  items.LONG_SLEEVE_SHIRTS,
  items.UNDER_SHIRTS,
  items.DRESS_SHIRTS,
  items.RUNNING_SHOES,
  items.BOOTS,
  items.DRESS_SHOES,
  items.DRESS_SOCKS,
  items.SUIT,
  items.HEELS,
  items.FLIP_FLOPS,
  items.RAIN_JACKET,
  items.WINTER_COAT,
  items.HIKING_CLOTHES,
  items.HIKING_BOOTS,
  items.WORKOUT_CLOTHES,
  items.THERMAL_WEAR,
  items.BEANIES,
  items.HATS,
  items.GLOVES,
  items.TIES,
  items.WATCH,
  items.SUNGLASSES])

export const rainClothes = [items.RAIN_JACKET, items.BOOTS, items.UMBRELLA]
export const coldWeatherClothes = [items.THERMAL_WEAR, items.BEANIES, items.GLOVES, items.WINTER_COAT, items.LONG_SLEEVE_SHIRTS]
export const hotWeatherClothes = [items.SHORTS, items.SHORT_SLEEVE_SHIRTS, items.HATS, items.SUNSCREEN]
export const chillyWeatherClothes = [items.LONG_SLEEVE_SHIRTS, items.PANTS]
export const internationalItems = [items.INTERNATIONAL_POWER_CONVERTER, items.PASSPORT, items.CURRENCY]
export const basicToiletries = [items.TOOTHBRUSH, items.TOOTHPASTE, items.SKIN_HAIR_CARE, items.HAND_SANITIZER, items.GLASSES, items.CONTACT_LENSES]
export const generalToiletries = [...basicToiletries, items.MOUTHWASH, items.FACE_WASH, items.LOTION, items.SHAVER]
export const generalTravel = [items.WATER_BOTTLE, items.HEADPHONES, items.PHONE_CHARGER, items.PORTABLE_BATTERY, items.FLIP_FLOPS]
export const hikingItems = [items.HIKING_BOOTS, items.HIKING_BACKPACK, items.HIKING_CLOTHES]
export const campingItems = [items.TENTS, items.SLEEPING_BAGS, items.CAMPING_PILLOWS, items.HIKING_BACKPACK, items.CAMPING_LIGHT, items.INSECT_REPELLANT, items.HEADLAMP, ...hikingItems]
export const formalMenItems = [items.DRESS_SHIRTS, items.UNDER_SHIRTS, items.DRESS_SHOES, items.DRESS_SOCKS, items.SUIT, items.WATCH, items.TIES, items.PANTS]
export const formalFemaleItems = [items.DRESS_OR_SKIRTS, items.HEELS, items.BRAS]
export const basicClothes = [items.SOCKS, items.UNDERWEAR, items.SHORT_SLEEVE_SHIRTS, items.PANTS, items.PANTS, items.FLIP_FLOPS, items.RUNNING_SHOES]
