import moment from "moment";

export function diffInDays(startDate: Date, endDate: Date) {
  return moment(endDate).diff(moment(startDate), "days");
}

export function convertUnixToDate(unixTime: number): Date {
  return new Date(unixTime * 1000)
}

export function formatUnixAsDate(unixTime: number): string {
  const theDate = convertUnixToDate(unixTime)
  const DOW = theDate.getDay()
  let dayOfWeek = 'Mon'
  if (DOW === 2) {
    dayOfWeek = 'Tue'
  }
  else if (DOW === 3) {
    dayOfWeek = 'Wed'
  }
  else if (DOW === 4) {
    dayOfWeek = 'Thu'
  }
  else if (DOW === 5) {
    dayOfWeek = 'Fri'
  }
  else if (DOW === 6) {
    dayOfWeek = 'Sat'
  }
  else if (DOW === 0) {
    dayOfWeek = 'Sun'
  }
  return `${dayOfWeek} ${theDate.getMonth()+1}/${theDate.getDate()}/${theDate.getFullYear()}`
}
