import { mdiWeatherCloudy, mdiWeatherCloudyAlert, mdiWeatherFog, mdiWeatherLightning, mdiWeatherPartlyCloudy, mdiWeatherPouring, mdiWeatherRainy, mdiWeatherSnowyHeavy, mdiWeatherSunny } from "@mdi/js"
import { FetchResponse, openweatherApiKey, ResponseStatus } from "../types/constants"
import { basicClothes, basicToiletries, campingItems, chillyWeatherClothes, clothesItems, coldWeatherClothes, formalFemaleItems, formalMenItems, generalToiletries, generalTravel, hikingItems, hotWeatherClothes, items, rainClothes } from "../types/packingItems"
import { packingItem, PackingListItems, TravelPreferences } from "../types/packingListTypes"
import { CityCoords } from "../types/TravelTypes"

export interface GetWeatherResponse extends FetchResponse {
  dailyWeather?: any[]
}

export async function getWeather(lat: number, lon: number): Promise<GetWeatherResponse> {
  const response = await fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&appid=${openweatherApiKey}&exclude=current,minutely,hourly,alerts&units=metric`)
  
  if (response.ok) {
    // console.log(`OK`)
    const body = await response.json()
    return { status: ResponseStatus.SUCCESS, dailyWeather: body.daily }
  }
  else {
    console.log(`Unable to connect to API`)
    return { status: ResponseStatus.FAILURE }
  }
}

// https://openweathermap.org/weather-conditions
export function getWeatherIcon(weatherCode: number): string {
  const codeGroup = Math.floor(weatherCode / 100)
  if (weatherCode === 800) { // clear
    return mdiWeatherSunny
  }
  else if (codeGroup === 2) { // thunderstorm
    return mdiWeatherLightning
  }
  else if (codeGroup === 3) { // drizzle
    return mdiWeatherRainy
  }
  else if (codeGroup === 5) { // rain
    return mdiWeatherPouring
  }
  else if (codeGroup === 6) { // snow
    return mdiWeatherSnowyHeavy
  }
  else if (codeGroup === 7) { // atmosphere
    return mdiWeatherFog
  }
  else if (weatherCode === 803 || weatherCode === 804) { // clouds (803 = 51-84%, 804 = 85-100%)
    return mdiWeatherCloudy
  }
  else if (weatherCode === 801 || weatherCode === 802) { // partly cloudy (801 = 11-25%, 802 = 25-50%)
    return mdiWeatherPartlyCloudy
  }
  return mdiWeatherCloudyAlert
}

export interface GetCityCoordinatesResponse extends FetchResponse {
  cities?: CityCoords[]
}

export async function getCoordinatesFromCity(cityName: string): Promise<GetCityCoordinatesResponse> {
  const response = await fetch(`https://api.openweathermap.org/geo/1.0/direct?q=${cityName}&limit=3&appid=${openweatherApiKey}`)
  
  if (response.ok) {
    // console.log(`OK`)
    const body = await response.json()
    const cities: CityCoords[] = (body as any[]).map(thisCity => { return { name: thisCity.name, lat: thisCity.lat, lon: thisCity.lon, country: thisCity.country, state: thisCity.state }
    })
    return { status: ResponseStatus.SUCCESS, cities }
  }
  else {
    console.log(`Unable to connect to API`)
    return { status: ResponseStatus.FAILURE }
  }
}

export function generatePackingSetByWeather(dailyWeather: any[]): Set<items> {
  let itemsToPack: Set<items> = new Set<items>()
  for (const thisDayWeather of dailyWeather) {
    if (thisDayWeather.pop > .7 || thisDayWeather.rain > .5) {
      // Percentage of Precipitation greater than 70%
      // Rainfall greater than 0.5 cm
      rainClothes.map(thisItem =>
        itemsToPack.add(thisItem))
    }
    if (thisDayWeather.feels_like.morn < 17 || thisDayWeather.feels_like.day < 17 || thisDayWeather.feels_like.eve < 17 || thisDayWeather.feels_like.night < 17) {
      // Feels like weather less than 17C / 62.6F
      if (thisDayWeather.feels_like.morn < 9 || thisDayWeather.feels_like.day < 9 || thisDayWeather.feels_like.eve < 9 || thisDayWeather.feels_like.night < 9) {
        // Feels like weather less than 9C / 48.2F
        coldWeatherClothes.map(thisItem => 
          itemsToPack.add(thisItem))
      }
      else {
        chillyWeatherClothes.map(thisItem => 
          itemsToPack.add(thisItem))
      }
    }
    else {
      hotWeatherClothes.map(thisItem => 
        itemsToPack.add(thisItem))
    }
  }
  return itemsToPack
}

export function generatePackingSetByPreferences(prefs?: TravelPreferences) {
  let itemsToPack: Set<items> = new Set<items>()
  basicClothes.map(thisItem => 
    itemsToPack.add(thisItem))
  generalTravel.map(thisItem => 
    itemsToPack.add(thisItem))
  if (prefs) {
    if (prefs.isTravellingLight) {
      basicToiletries.map(thisItem =>
        itemsToPack.add(thisItem))
    }
    else {
      generalToiletries.map(thisItem =>
        itemsToPack.add(thisItem))
    }
    if (prefs.isHiking) {
      hikingItems.map(thisItem =>
        itemsToPack.add(thisItem))
    }
    if (prefs.isCamping) {
      campingItems.map(thisItem =>
        itemsToPack.add(thisItem))
    }
    if (prefs.isFormalEvent) {
      if (prefs.isMale) {
        formalMenItems.map(thisItem =>
          itemsToPack.add(thisItem))
      }
      if (prefs.isFemale) {
        formalFemaleItems.map(thisItem =>
          itemsToPack.add(thisItem))
      }
      if (!prefs.isMale && !prefs.isFemale) {
        itemsToPack.add(items.FORMAL_WEAR)
        itemsToPack.add(items.DRESS_SHOES)
        itemsToPack.add(items.DRESS_SOCKS)
      }
    }
    if (prefs.isSwimming) {
      itemsToPack.add(items.SWIMWEAR)
      itemsToPack.add(items.FLIP_FLOPS)
    }
  }
  return itemsToPack
}

export function formatTemperature(celsiusTemp: number, isFahrenheit: boolean): string {
  if (!!isFahrenheit) {
    return `${Math.round((celsiusTemp*(9/5))+32)}°F`
  } else {
    return `${Math.round(celsiusTemp)}°C`
  }
}

export async function generatePackingList(cityCoords: CityCoords, dailyWeather: any[], travelPreferences: TravelPreferences): Promise<PackingListItems | false> {
    // console.log(` - dailyWeather: ${JSON.stringify(dailyWeather)}`)
    // console.log(` * travelPreferences: ${JSON.stringify(props.travelPreferences)}`)
    let packingSet = generatePackingSetByPreferences(travelPreferences)
    generatePackingSetByWeather(dailyWeather).forEach(item => packingSet.add(item))
    let newPackingItems: packingItem[] = []
    packingSet.forEach(item => {
      newPackingItems.push({ item, isChecked: false })
    })
    newPackingItems.sort((a, b) => {
      if (clothesItems.has(a.item) && !clothesItems.has(b.item))
        return -1
      else if (clothesItems.has(a.item) && clothesItems.has(b.item))
        return 0
      else if (!clothesItems.has(a.item) && clothesItems.has(b.item))
        return 1
      else
        return 0
    })
    
    const newPackingListItems: PackingListItems = { packingItems: newPackingItems, createdAt: new Date(), cityName: cityCoords.name, countryCode: cityCoords.country, state: cityCoords.state }
    return newPackingListItems
}
