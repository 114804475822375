export const countryAndLanguage = [
  { value: 'AUT', label: 'Austria' },
  { value: 'FRA', label: 'France' },
  { value: 'DEU', label: 'Germany' },
  { value: 'ITA', label: 'Italy' },
  { value: 'SVK', label: 'Slovakia' },
  { value: 'CHE', label: 'Switzerland' },
]

export type UsefulPhrases = {
  hello: string;
  excuseMe: string;
  please: string;
  thankYou: string;
  sorry: string;
  yes: string;
  no: string;
  goodbye: string;
  whereIs: string;
  imGoingTo: string;
  doYouSpeak: string;
  english: string;
  iDontUnderstand: string;
  canYouHelpMe: string;
  aTableFor: string;
  one: string;
  two: string;
  three: string;
  four: string;
  person: string;
  people: string;
  canIHave: string;
  menu: string;
  water: string;
  waterBottle: string;
  tapWater: string;
  coffee: string;
  theBill: string;
}

export const languageAndPhrases: { [key: string]: UsefulPhrases } = {
  'English': {
    hello: 'Hello',
    excuseMe: 'Excuse me',
    please: 'Please',
    thankYou: 'Thank you',
    sorry: 'Sorry',
    yes: 'Yes',
    no: 'No',
    goodbye: 'Goodbye',
    whereIs: 'Where is ',
    imGoingTo: 'I\'m going to ',
    doYouSpeak: 'Do you speak ',
    english: 'English',
    iDontUnderstand: 'I don\'t understand ',
    canYouHelpMe: 'Can you help me ',
    aTableFor: 'A table for ',
    one: 'one',
    two: 'two',
    three: 'three',
    four: 'four',
    person: 'person',
    people: 'people',
    canIHave: 'Can I have ',
    menu: 'Menu',
    water: 'Water',
    waterBottle: 'Water bottle',
    tapWater: 'Tap water',
    coffee: 'Coffee',
    theBill: 'The bill / check'
  },
  'German': {
    hello: 'hallo',
    excuseMe: 'entschuldigung',
    please: 'bitte',
    thankYou: 'danke schön',
    sorry: 'entschuldigung',
    yes: 'ja (\'ya\')',
    no: 'nein',
    goodbye: 'auf wiedersehen',
    whereIs: 'wo ist',
    imGoingTo: 'ich gehe',
    doYouSpeak: 'sprechen sie',
    english: 'englisch',
    iDontUnderstand: 'ich verstehe nicht',
    canYouHelpMe: 'können sie mir helfen',
    aTableFor: 'ein tisch für',
    one: 'ein',
    two: 'zwei',
    three: 'drei',
    four: 'vier',
    person: 'person',
    people: 'personen',
    canIHave: 'kann ich haben',
    menu: 'speisekarte',
    water: 'wasser',
    waterBottle: 'wasser in flaschen haben',
    tapWater: 'leitungswasser',
    coffee: 'kaffee',
    theBill: 'die rechnung'
  },
  'French': {
    hello: 'bonjour',
    excuseMe: 'excuse moi',
    please: 's\'il vous plaît',
    thankYou: 'merci',
    sorry: 'pardon',
    yes: 'oui',
    no: 'non',
    goodbye: 'au revoir',
    whereIs: 'ou est',
    imGoingTo: 'je vais',
    doYouSpeak: 'parlez-vous',
    english: 'anglais',
    iDontUnderstand: 'je ne comprends pas',
    canYouHelpMe: 'pouvez-vous m\'aider',
    aTableFor: 'une table pour',
    one: 'une',
    two: 'deux',
    three: 'trois',
    four: 'quatre',
    person: 'personne',
    people: 'personnes',
    canIHave: 'puis-je avoir',
    menu: 'menu',
    water: 'l\'eau',
    waterBottle: 'une bouteille d\'eau',
    tapWater: 'l\'eau du robinet',
    coffee: 'café',
    theBill: 'l\'addition'
  },
  'Slovak': {
    hello: 'dobrý deň',
    excuseMe: 'ospravedlnte ma',
    please: 'prosím',
    thankYou: 'Ďakujem (\'ja-ku-yem\')',
    sorry: 'prepáč',
    yes: 'áno',
    no: 'nie',
    goodbye: 'zbohom',
    whereIs: 'kde je (\'kdeh yeh\')',
    imGoingTo: 'idem na',
    doYouSpeak: 'hovoríš po',
    english: 'anglicky (\'ahn-glits-kih\')',
    iDontUnderstand: 'nerozumiem',
    canYouHelpMe: 'môžeš mi pomôcť',
    aTableFor: 'stôl pre',
    one: 'jeden (\'yeh-den\')',
    two: 'dva',
    three: 'tri',
    four: 'štyri (\'shtiri\')',
    person: 'osoba',
    people: 'osoby',
    canIHave: 'môžem mať',
    menu: 'jedálny lístok (\'yeh-dahl-nih lees-tohk\')',
    water: 'voda',
    waterBottle: 'fľaša na vodu (\'fla-sha\')',
    tapWater: 'voda z vodovodu',
    coffee: 'kávu',
    theBill: 'šek (\'shehk\')'
  },
  'Italian': {
    hello: 'ciao (\'chow\')',
    excuseMe: 'scusa',
    please: 'per favore',
    thankYou: 'grazie',
    sorry: 'scusa',
    yes: 'sì',
    no: 'no',
    goodbye: 'ciao (\'chow\')',
    whereIs: 'dov\'è ',
    imGoingTo: 'sto andando a',
    doYouSpeak: 'parli',
    english: 'inglese (\'een-gleh-zeh\')',
    iDontUnderstand: 'non capisco',
    canYouHelpMe: 'mi potete aiutare',
    aTableFor: 'un tavolo per',
    one: 'uno',
    two: 'due (\'dweh\')',
    three: 'tre',
    four: 'quattro',
    person: 'persona',
    people: 'persone (\'per-sohn-eh\')',
    canIHave: 'posso avere',
    menu: 'menù (\'meh-noo\')',
    water: 'acqua',
    waterBottle: 'bottiglia d\'acqua',
    tapWater: 'acqua di rubinetto',
    coffee: 'caffè',
    theBill: 'il conto'
  },
  // 'LANGUAGE': {
  //   hello: '',
  //   excuseMe: '',
  //   please: '',
  //   thankYou: '',
  //   sorry: '',
  //   yes: '',
  //   no: '',
  //   goodbye: '',
  //   whereIs: '',
  //   imGoingTo: '',
  //   doYouSpeak: '',
  //   english: '',
  //   iDontUnderstand: '',
  //   canYouHelpMe: '',
  //   aTableFor: '',
  //   one: '',
  //   two: '',
  //   three: '',
  //   four: '',
  //   person: '',
  //   people: '',
  //   canIHave: '',
  //   menu: '',
  //   water: '',
  //   waterBottle: '',
  //   tapWater: '',
  //   coffee: '',
  //   theBill: ''
  // },
}