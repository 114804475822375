export enum GameTypes {
  FAMILY_FRIENDLY,
  DRINKING_GAME,
  INDOOR,
  OUTDOOR,
}

export interface PartyPlayGame {
  title: string,
  rules: string,
}

export const DrinkingGames: PartyPlayGame[] = [
  {
    title: 'Letter Up',
    rules: 'Pick any letter A-Z. If anyone\'s first name begins in a letter after that letter, take a shot.'
  }, {
    title: 'Never Have I Ever',
    rules: 'The first player starts by saying a simple statement about something they have never done before starting with "Never have I ever". Anyone who at some point in their life has done the action that the first player says, must drink. Then the game continues around the circle, and the next person makes a statement.'
  }, {
    title: 'Friendly Fire',
    rules: 'You must take a drink.'
  }, {
    title: 'Rock, Paper, Scissors',
    rules: 'Pick one person from audience. Cover eyes, Duel rock paper scissors best out of 3. If you win, everyone else drinks. If the audience person wins, you have to drink.'
  }, {
    title: 'Colors',
    rules: 'Name a color. Everyone not wearing that color must drink.'
  }, {
    title: 'Flip cup',
    rules: 'Pick an audience member. You have 30 seconds to flip the cup as many times. Loser group drinks.'
  }, {
    title: 'WrestleMania',
    rules: 'Pick an audience member. You have 30 seconds to have a thumb wrestling match. Winner doesn\'t have to drink.'
  }, {
    title: 'Story Time',
    rules: 'Tell us your most embarrassing vomit story'
  }, {
    title: 'Draw Something',
    rules: 'Pick an audience member. That person will draw a tattoo on you'
  }, {
    title: 'Truth or Dare',
    rules: 'Example Truth Questions: Describe the strangest dream you\'ve had in your life, What is the meanest thing you have done in your life, What is your biggest sexual fantasy, Tell me about your first kiss. || Example Dares: Act like a dog and get petted by everyone, Draw a face around your belly button, Serenade the person to your right, Sell a piece trash to someone in the group using your best salesmanship.'
  }, {
    title: '50-50',
    rules: 'Pour two shots: one with water and one with alcohol. Turn around. Have the audience members cover both shots with cups. Turn around and select one cup. Drink the shot underneath that cup.'
  }, {
    title: 'Fives',
    rules: 'The first person counts to three, and then shouts out a multiple of five, which is their guess for how many people will show their \'five\'. Each player either opens their hand for a \'five\' or keeps their hand closed. If you get the number right, you can remove your hand from the game and you\'re safe, Then it\'s the next player\'s go. If you\'re left with your hand in, you drink.'
  }
]


export const FamilyFriendlyGames: PartyPlayGame[] = [
  {
    title: 'Picture Perfect',
    rules: 'Everybody get together to take a group selfie/photo.'
  }, {
    title: 'Gangnam Style',
    rules: 'Dance.'
  }, {
    title: 'Hidden Talent',
    rules: 'What is your greatest hidden talent?'
  }, {
    title: 'Animal Time',
    rules: 'Pick an animal. You must act out that animal for 20 seconds.'
  }
]
