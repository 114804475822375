import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GridLoader } from 'react-spinners';
import { DrinkingGames, FamilyFriendlyGames, PartyPlayGame } from './games';
import './PartyPlayView.css';

export function PartyPlayView() {
  const [isRandomizing, setIsRandomizing] = useState<boolean>(false)
  const [waitTime, ] = useState<number>(2)
  const [allGames, setAllGames] = useState<PartyPlayGame[]>([])
  const [game, setGame] = useState<PartyPlayGame>()

  useEffect(() => {
    // Randomize to all games
    let allOfTheGames = [...FamilyFriendlyGames, ...DrinkingGames]
    shuffle(allOfTheGames)
    setAllGames(allOfTheGames)
  }, [])
  
  const shuffle = (allOfTheGames: any[]) => {
    let currentIndex = allOfTheGames.length, randomIndex

    // While there remain elements to shuffle
    while (currentIndex !== 0) {
      // Pick a remaining element
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element
      [allOfTheGames[currentIndex], allOfTheGames[randomIndex]] = [allOfTheGames[randomIndex], allOfTheGames[currentIndex]]
    }
    return allOfTheGames
  }

  const pullGame = () => {
    setIsRandomizing(true)
    // Pick a game
    if (allGames.length > 1)
      setGame(allGames.pop())
    else {
      let allOfTheGames = [...FamilyFriendlyGames, ...DrinkingGames]
      shuffle(allOfTheGames)
      setGame(allOfTheGames.pop())
      setAllGames(allOfTheGames)
    }
    setTimeout(() => {
      setIsRandomizing(false)
    }, waitTime * 1000)
  }

  const gameRulesView = () => {
    return (
      <div className='marginSides-3em'>
        {
          game && 
          <div className='marginBottom-1em'>
            <div className='game-title'>{game.title}</div>
            <div className='game-rules'>{game.rules}</div>
          </div>
        }
        <div>
          <Button size='lg' onClick={()=>{ pullGame() }}>
            Random Game
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className='center'>
      <div className='marginTopBottom-3em'>
        <div className='party-play-title marginBottom-1em'>
          Party Play
        </div>
        {isRandomizing ?
          <div className='marginTopBottom-3em'>
            <GridLoader />
          </div> : 
          gameRulesView()}
      </div>
    </div>
  )
}